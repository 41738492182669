import { Image, useTheme } from "@aws-amplify/ui-react";

export default function Header() {
  const { tokens } = useTheme();

  return (
      <Image
      alt="logo"
      id="ecs-logo"
      src="https://www.ecs-hi.com/hosted/images/04/eeb2dc2bfd43538644c5e53ae24708/ECS-Color-2-.png"
      padding={tokens.space.medium}
    />
  );
}
