import { Flex, Text, useTheme } from "@aws-amplify/ui-react";
import Link from '@mui/material/Link';

export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; Copyright 2023  - All Rights Reserved, <Link href="https://www.ecs-hi.com">Elite Construction Services</Link></Text>
    </Flex>
  );
}
