import React from "react";
import "./App.css";
import Container from "@mui/material/Container";
import { FormControl, MenuItem, Select, InputLabel } from "@mui/material/";
import Header from "./../Header/Header.js";
import { Footer } from "../Footer/Footer";
import Link from "@mui/material/Link";

function App() {
  const handleClick = (event) => {
    let url = event.target.dataset.value;
    window.open(url, "_blank");
  };

  return (
    <Container maxWidth="sm" className="App">
      <Header />
      <FormControl fullWidth>
        <InputLabel id="building-select-label">
          Please select your building
        </InputLabel>
        <Select
          id="building-select"
          label="Please select your building"
          onClick={handleClick}
        >
          <MenuItem value="https://castlesurf.ecs-hi.com">Castle Surf</MenuItem>
          <MenuItem value="https://fairwayhouse.ecs-hi.com">
            Fairway House
          </MenuItem>
          <MenuItem value="https://wilderregent.ecs-hi.com">
            Wilder Regent
          </MenuItem>
        </Select>
      </FormControl>
      <Footer />
    </Container>
  );
}
export default App;
